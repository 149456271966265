<div class="wrapper login-section ">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-6">
                    <a routerLink="/" class="logo">
                        <img src="assets/images/Growthdrivers.svg" />
                    </a>
                </div>
                <div class="col-6">
                    <ul class="navbar">
                        <li><a [class.active]="activeClass==1"  (click)="gotoTerms()">Terms of Use</a></li>
                        <li><a [class.active]="activeClass==2"  (click)="gotoPrivacy()">Privacy Policy</a></li>
                        <li><a [class.active]="activeClass==3"  (click)="gotoFaq()">FAQs</a></li>
                        <li><a [class.active]="activeClass==4"  routerLink="/contactus">Contact Us</a></li>
                        <li><a [class.active]="activeClass==5"  routerLink="/feedback">Feedback</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container feedback">
        <div class="container">
            <div class="feedback-comments">
                <p>For inquiries, feedback, account deletion requests, or any concerns about the Growthdrivers app, please contact us at <a href="mailto:growthdrivers.app@guidewise.io"> growthdrivers.app@guidewise.io</a>. Our dedicated team is here to assist you promptly and values your input. We are committed to ensuring your experience with our app is enjoyable and rewarding. Your communication is vital to us, and we look forward to assisting you on your Growthdrivers journey.</p>
                <div class="downloadBtn_wrap">
                    <a href="https://apps.apple.com/sn/app/growthdrivers/id1564691592"><img src="../../../assets/images/appstore.png" /> </a>
                    <a href="https://play.google.com/store/apps/details?id=com.app.growthdriver&pli=1"><img src="../../../assets/images/google_play.png" /> </a>
                </div>
            </div>
        </div>
    </div>

    <!--  -->
    <div class="footerWrap">
        <div class="copyright">
            <a href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer" class="logo_a mt-2">
                <img src="../../../assets/images/guidewise_primary mark_final.jpg" class="logo">
            </a>
            <span> Growthdrivers R2.0.0 </span><p> © 2021 - 2023 Guidewise </p>
        </div>
    </div>
</div>