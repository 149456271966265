import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { EllipsisModule } from 'ngx-ellipsis';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InterceptorService } from './services/interceptor.service';
import { LoaderComponent } from './core/components/loader/loader.component';
import { RouterModule } from '@angular/router';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { DataShareService } from './services/data-share.service';
import { NavigationServiceService } from "./services/navigation-service.service";
import { NgOtpInputModule } from  'ng-otp-input';
import { IdleComponent } from './dashboard/idle/idle.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

/////////////////////////////////////

// import { DateFormatPipe } from './date-format.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    IdleComponent,
    //  DateFormatPipe
  ],

  imports: [
    NgOtpInputModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    LoginModule,
    ModalModule.forRoot(),
    NgbModule,
    RouterModule,
    EllipsisModule,
    MatMenuModule,
    MatIconModule,
    MatSnackBarModule,
    NgxSpinnerModule
  ],
  exports: [
    ModalModule,
    // DateFormatPipe
  ],
  providers: [AsyncPipe, BsModalRef, NavigationServiceService, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true,
  },
    Title,
    DataShareService

    // DateFormatPipe
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
