import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { AdminService } from '../../services/admin.service';
import { apiEndPoint } from '../../shared/apiEndPoints';
import { generatePasswordModel } from '../../shared/models/login-model';
import * as CONSTANT from "../../services/constants";
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-generate-password',
  templateUrl: './generate-password.component.html',
  styleUrls: ['./generate-password.component.scss']
})
export class GeneratePasswordComponent implements OnInit {

  public form: FormGroup;
  confirmPassword: string = '';
  token: string = '';
  showError = false;
  loading: boolean = false;
  generatePasswordModel = new generatePasswordModel();
  modalRef: BsModalRef;
  isTextFieldType1 = false;
  isTextFieldType2 = false;

  @ViewChild('modalSave', {static: false}) public modalSave: TemplateRef<any>;

  constructor(private formBuilder: FormBuilder, private router: Router, private activeRoute: ActivatedRoute, private api: AdminService,private modalService: BsModalService) {
    this.form = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required,Validators.pattern(CONSTANT.nonWhiteSpaceRegExp)
      ])],
      userName: ['', Validators.compose([Validators.required])],
      lastName: ['', Validators.compose([Validators.required, Validators.maxLength(20),
      Validators.pattern(CONSTANT.nonWhiteSpaceRegExp)])
      ],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(16), Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*.#?&])[A-Za-z\dA-Z\d@$!%*.#?&]{8,16}$/)])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      acceptPolicy:['',Validators.compose([Validators.requiredTrue])]
    },
      {
        validator: this.MustMatch('password', 'confirmPassword')
      });
    this.activeRoute.params.subscribe(res => {
      this.token = res.token;
    });
    localStorage.setItem('lastRouteGen', this.router.url);

  }

  ngOnInit() {
    console.log('token')
    this.checkToken();
  }

  checkToken() {
    const data = {
      token: this.token
    }
    this.api.postData(apiEndPoint.LINKVERFY, data).subscribe(res=>{
     },err => {
    
      this.modalRef = this.modalService.show(this.modalSave, {
        class: 'modal-dialog w-3',
        backdrop: 'static'
      });
    })

  }

  generatePassword() {
    this.showError = false;
    let firstName = this.generatePasswordModel.firstName.trim();
    let lastName = this.generatePasswordModel.lastName.trim();
    let userName = this.generatePasswordModel.userName.trim();
    if (this.form.valid) {
      this.showError = false;
      this.loading = true;
      const data = {
        first_name: firstName,
        last_name: lastName,
        user_name: userName,
        full_name: firstName || '' + ' ' + lastName,
        password: this.generatePasswordModel.password,
        access_token: this.token,
        verify_email: 1
      };
      this.api.postData(apiEndPoint.GENERATE_PASSWORD, data)
        .subscribe(res => {
          this.loading = false;
          if ((res.status) && (res.status == '200')) {
            this.generatePasswordModel = new generatePasswordModel();
            this.confirmPassword = "";
            this.api.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">Successfully created</span>', '');
            this.router.navigate(['/login']);
            //window.open(environment.adminUrl,"_self")
          }
          else if (res.message) {
            this.api.errorToast( '<i class="mdi bigSize mdi-alert-circle-outline"></i> <span class="font_size">' +res.message+'</span>', '');
          } else {
            this.api.errorToast( '<i class="mdi bigSize mdi-alert-circle-outline"></i> <span class="font_size">Something went wrong!</span>', '');

          }
        },
          err => {
            console.log(err);
            this.loading = false;
          });
    } else {
      this.showError = true;
    }
  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  goToLogIn() {
    this.router.navigate(['/login']);
  }

  togglePasswordFieldType(num: any) {
    if (num == 1) {
      this.isTextFieldType1 = !this.isTextFieldType1
    }
    if (num == 2) {
      this.isTextFieldType2 = !this.isTextFieldType2
    }
  }
}
