<!-- Session Timeout modal -->
<div class="modal fade show idle_popup" style="display: block;" *ngIf="sessionPopUp">
    <div class="modal-dialog w-3 modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center">Session Timeout</h5>
            </div>
            <div class="popupBody">
                <p class="text-center">Please enter PIN to resume</p>
                <div class="otpWrap">
                    <div class="otpBox">
                        <ng-otp-input #ngOtpInput *ngIf="showOtpComponent" (onInputChange)="pinChange($event)" [config]="config">
                        </ng-otp-input>
                        <!-- <div class="error" *ngIf="pinError">
                            *Please enter the PIN</div> -->
                    </div>
                    <div class="forgotPin p-0">
                        <a  (click)="forgotPin(forgotPinPopup)">Forgot PIN?</a>
                    </div>
                </div>
                <!-- <div class="submit-btn">
                    <button class="common-btn" (click)="otpSubmit()">Submit</button>
                </div> -->
            </div>
        </div>
    </div>
</div>

<!--  forgot modal -->
<ng-template #forgotPinPopup>
    <div class="modal-header">
        <h5 class="modal-title">
            <div *ngIf="showResetError" class="text-center error font-weight-normal">
                Note: You have exceed the limit, please reset the password.
            </div>
            <a *ngIf="!showResetError && !forgotPassWord"  (click)="sessionPopUp = true;closePopup() " class="closePopup">
                <img src="assets/images/arrow-back.svg"></a> 
            Reset PIN</h5>
    </div>
    <div class="popupBody">
        <p>Please enter your current password</p>
        <div class="otpWrap">
            <form [formGroup]="pwdForm" (ngSubmit)="checkPassword(pwdForm, updatePinPopup)">
                <div class="form-group text-left" style="margin-bottom: 20px">
                    <input [type]="passwordType" class="form-control" placeholder="Password*" formControlName="password" />

                    <span class="error" *ngIf="pwdForm.controls['password'].hasError('required') && showError">*Please enter your password.</span>

                    <a  class="showPass" (click)="togglePassword()">
                        <img [src]="passwordShown == false? 'assets/images/eye-off-2.svg' :'assets/images/ic_eye_show_red.svg' " tabindex=3 /></a>
                </div>
                <div class="forgotPin">
                    <a  (click)="forgotPwd(forgotPwdPopup)">Forgot Password?</a>
                </div>
                <div class="submit-btn">
                    <button class="common-btn">CONTINUE</button>
                </div>
            </form>
        </div>

    </div>
</ng-template>

<!--  forgot modal -->
<ng-template #forgotPwdPopup>
    <div class="modal-header">
        <h5 class="modal-title">
            <a  (click)="goToForgot(forgotPinPopup)" class="closePopup"><img src="assets/images/arrow-back.svg"></a>Forgot Password?</h5>
    </div>
    <div class="popupBody">
        <p>Don't worry. Resetting your password is easy. Simply enter your work email address below.
        </p>
        <div class="form-box">
            <form [formGroup]="pwdResetForm" (ngSubmit)="sendEmail(pwdResetForm, forgotPinPopup)">
                <div class="form-group" style="margin-bottom: 20px">
                    <input type="email" class="form-control" placeholder="Work Email Address*" formControlName="email" />
                    <div class="error" *ngIf="pwdResetForm.controls['email'].hasError('required') && showError">
                        *Please enter a valid email address
                        <!-- <span class="tooltip">Description of what they need to put here.</span> -->
                    </div>
                    <div class="error" *ngIf="showError&&pwdResetForm.controls.email.errors?.pattern ">*Please enter a valid email address</div>
                </div>
                <div class="submitWrap">
                    <button class="btn btn-primary"> UPDATE </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<!-- Session update pin modal -->
<ng-template #updatePinPopup>
    <div class="modal-header">
        <h5 class="modal-title">
            <a  class="closePopup" (click)="goToForgot(forgotPinPopup)"><img src="assets/images/arrow-back.svg"></a>Reset PIN</h5>
    </div>
    <div class="popupBody">
        <p>Please complete fields below to reset PIN.</p>
        <div class="otpWrap">
            <label>New PIN</label>
            <div class="otpBox">
                <ng-otp-input *ngIf="showPinChangeComponent" (onInputChange)="newPinChange($event)" [config]="config">
                </ng-otp-input>
                <div class="error" *ngIf="newPinError">
                    *Please enter your new PIN.</div>
            </div>
            <label>Confirm New PIN</label>
            <div class="otpBox">
                <ng-otp-input *ngIf="showPinChangeComponent" (onInputChange)="confirmPinChange($event)" [config]="config"></ng-otp-input>
                <div class="error" *ngIf="confirmPinError">*Please enter your confirm PIN.</div>
                <div class="error" *ngIf="notSameError">*PINs do not match. Please try again.</div>
            </div>
        </div>
        <div class="submit-btn">
            <button class="common-btn" [class.disable]="!newPin || !confirmPin || confirmPin.length < 4 || newPin.length < 4" [disabled]="!newPin || !confirmPin || confirmPin.length < 4 || newPin.length < 4" (click)="changePinSubmit()">RESET</button>
        </div>
    </div>
</ng-template>