import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CreateProfileComponent } from './login/create-profile/create-profile.component';
import { AuthProfileGuard } from './core/guards/auth-profile.guard';

import { GeneratePasswordComponent } from './login/generate-password/generate-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { LinkExpiredComponent } from './login/link-expired/link-expired.component';
import { MessageSuccessComponent } from './login/message-success/message-success.component';
////////////////////////////////////////////////
import { TermsConditionsComponent } from './login/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './login/privacy-policy/privacy-policy.component';
import { FaqComponent } from './login/faq/faq.component';
import { EnrollmentComponent } from './login/enrollment/enrollment.component';
import { ContactusComponent } from './login/contactus/contactus.component';
import { UserObservationsComponent } from './login/user-observations/user-observations.component';
import { FeedbackComponent } from './login/feedback/feedback.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'user-observations/:id', component: UserObservationsComponent, data: {title: 'Observations'} },
  { path: 'user-observations', component: UserObservationsComponent,data: {title: 'Observations'} },
  { path: 'terms-conditions', component: TermsConditionsComponent,data:{
    title:'Terms of Use'
  } },
  {
    path: 'privacy-policy', component: PrivacyPolicyComponent, data: {
      title: 'Privacy Policy'
    }
  },
  {
    path: 'enrollment', component: EnrollmentComponent, data: {
      title: 'Enrollment Agreement'
    }
  },
  { path: 'feedback', component: FeedbackComponent, data: {title: 'Feedback'} },
  { path: 'faq', component: FaqComponent, data: {title: 'FAQs'} },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: {title: 'Forgot Password'} }, 
  { path: 'LinkExpired', component: LinkExpiredComponent },
  { path: 'successMessage', component: MessageSuccessComponent },
  { path: 'contactus', component: ContactusComponent, data: {title: 'Contact Us'} },
  { path: 'generate-password/:token', component: GeneratePasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent, data: {title: 'Reset Password'}  },
  { path: 'create-profile', component: CreateProfileComponent, canActivate: [AuthProfileGuard] , data: {title: 'Create Profile'}},
  { path: '', redirectTo: '/login', pathMatch: 'full' },  {
    path: '',canActivate: [AuthGuard], loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
