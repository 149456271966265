<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container">
        <div class="container">
            <div class="form-container">
                <div class="itemBox">
                    <h2><a class="backArrow pointer" (click)="goBack()"> How can we help?</a></h2>
                    <p>Send us a message!</p>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-5 rightSection">
                        <div class="innerBox">
                            <img src="./../../../assets/images/envelope.png">

                        </div>
                    </div>

                    <div class="col-12 col-lg-7 leftSection">
                        <div class="msgwrap" *ngIf="showmsg">
                            <div class="successMsg">
                                <img src="../../../assets/images/sucess.svg">
                                <p style="padding-bottom: 10px ;"><strong>Thank you for contacting us.</strong> </p>
                                <p>{{msg}}</p>
                            </div>
                        </div>
                        <div class="loginWrap" *ngIf="!showmsg">
                            <div class="form-box">
                                <form [formGroup]="contactUs" (ngSubmit)="onSubmit(contactUs)">

                                    <div class="form">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="First Name*" formControlName="firstName" maxlength="30" (keyup)="trigger($event,'first')"/>
                                            <div class="warnings" *ngIf="contactUs.controls['firstName'].hasError('required') && showError">
                                                *Please enter first name.
                                            </div>
                                            <div class="warnings" *ngIf="showError&&contactUs.controls.firstName.errors?.minlength">
                                                First name should have a minimum of 3 characters</div>
                                            <div class="warnings" *ngIf="!contactUs.controls['firstName'].hasError('required') && showError&&!contactUs.controls.firstName.errors?.minlength && contactUs.controls['firstName'].errors?.whitespace">
                                                *Please enter valid first name
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Last Name*" formControlName="lastName" maxlength="30" (keyup)="trigger($event,'last')"/>
                                            <div class="warnings" *ngIf="contactUs.controls['lastName'].hasError('required') && showError">
                                                *Please enter last name.
                                            </div>
                                            <div class="warnings" *ngIf="showError&&contactUs.controls.lastName.errors?.minlength">
                                                First name should have a minimum of 3 characters</div>
                                            <div class="warnings" *ngIf="!contactUs.controls['lastName'].hasError('required') && showError&&!contactUs.controls.lastName.errors?.minlength && contactUs.controls['lastName'].errors?.whitespace">
                                                *Please enter valid last name
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <input type="email" class="form-control" placeholder="Email Address*" formControlName="email" />
                                            <div class="warnings" *ngIf="contactUs.controls['email'].hasError('required') && showError">
                                                *Please enter work email address.
                                            </div>
                                            <div class="warnings" *ngIf="contactUs.controls['email'].hasError('pattern') && showError">
                                                *Please enter valid email address.
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <textarea class="form-control pb-20" style="padding-bottom: 20px !important;" type="text" placeholder="Your message..." formControlName="message" maxlength="600">
                                            </textarea>
                                            <span class="text-count" [class.warnings]="contactUs?.controls['message']?.value?.length == 600" >
                                                {{contactUs?.controls['message']?.value?.length}} / 600
                                            </span>
                                            <div class="warnings" *ngIf="contactUs.controls['message'].hasError('required') && showError">
                                                *Please enter message.
                                            </div>
                                            <div class="warnings" *ngIf="!contactUs.controls['message'].hasError('required') && showError && contactUs.controls['message'].errors?.whitespace">
                                                *Please enter valid message
                                            </div>
                                        </div>
                                        <div class="form-group captcha">
                                            <re-captcha id="recaptcha" name="recaptcha" [(ngModel)]="token" [ngModelOptions]="{standalone: true}"></re-captcha>
                                            <div class="warnings" *ngIf="captchaError">
                                                *Please check the recaptcha
                                            </div>

                                        </div>
                                        <div class="submitWrap">
                                            <button class="btn btn-primary fill create_btn" [disabled]="checkValidForm() || !token" [class.disable]="checkValidForm() || !token">SUBMIT</button>

                                        </div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footerWrap">
        <div class="copyright">
            <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R2.0.0</span>
            <p>&copy; 2021 - 2023 Guidewise</p>
        </div>
    </div>

</div>