import { Component, Input, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-pdf-down-load',
  templateUrl: './pdf-down-load.component.html',
  styleUrls: ['./pdf-down-load.component.scss']
})
export class PdfDownLoadComponent implements OnInit {
  @Input() showPdfData;
  showPdf;
  // @ViewChild('myHtmlElement') myHtmlElement: ElementRef;

  constructor() { }

  ngOnInit(): void {
    if(this.showPdfData) {
      this.showPdf = this.showPdfData;
      setTimeout(() => {
        this.generatePDF();
      }, 1000);
    }
  }
  
  generatePDF() {
    const doc = new jsPDF('p', 'pt', 'a4');
    const elementToPrint = document.getElementById('myHtmlElement');
    const elementWidth = elementToPrint.clientWidth;
    const elementHeight = elementToPrint.clientHeight;
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const html2canvasOpts = {
        allowTaint: true,
        scale: 2,
        useCORS: true,
        width: elementWidth,
        height: elementHeight,
        scrollX: 0,
        scrollY: -window.scrollY,
    };
    let position = 0;
    let imgData = '';
    let totalPages = 0;

    html2canvas(elementToPrint, html2canvasOpts).then((canvas) => {
        const imgProps = doc.getImageProperties(canvas.toDataURL('image/png'));
        const pdfWidth = pageWidth;
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const maxPosition = pdfHeight;
        const lastPageHeight = elementHeight % pageHeight;
        while (position < maxPosition) {
            imgData = canvas.toDataURL('image/png', 1.0);
            doc.addImage(imgData, 'PNG', 0, -position, pdfWidth, pdfHeight);

            // Add footer to every page
            doc.setFontSize(5);
            doc.text(`Growthdrivers R 2.0.0 © 2021 - 2023 `, pageWidth - 83, pageHeight - 10);
            totalPages++;

            position += pageHeight;
            if (position < maxPosition) {
                doc.addPage();
            }
        }
        if (lastPageHeight > 0) {
            imgData = canvas.toDataURL('image/png', 1.0);
            doc.addImage(
                imgData,
                'PNG',
                0,
                -position,
                pdfWidth,
                (imgProps.height * lastPageHeight) / imgProps.width
            );

            // Add footer to the last page
            doc.setFontSize(5);
            doc.text(`Growthdrivers R 2.0.0 © 2021 - 2023`, pageWidth - 83, pageHeight - 10);
            totalPages++;
        }

        doc.save('observation_review.pdf');
    });
}
  
  replaceWithName(name) {
    return name.replace('{{user_name}}', this.showPdfData?.subject?.first_name + ' ' + this.showPdfData?.subject?.last_name)
  }

  opacityValue(value) {
    let number = parseInt(value);
    if (number > 9) {
      return "1";
    }
    else if (number < 1) {
      return "0.1";
    } else {
      return "0." + number;
    }
  }
}
