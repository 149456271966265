import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { VoiceRecognitionServiceService } from 'src/app/services/voice-recognition-service.service';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import html2canvas from 'html2canvas';
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfMake from "pdfmake/build/pdfmake";
import { BsModalService } from 'ngx-bootstrap/modal';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Location } from '@angular/common';
import { EChartOption } from 'echarts';
@Component({
  selector: 'app-user-observations',
  templateUrl: './user-observations.component.html',
  styleUrls: ['./user-observations.component.scss']
})
export class UserObservationsComponent implements OnInit {
  title;
  backTodetail: any;
  baseUrl: string = environment.backend.baseURL;
  dropdownVal;
  pastDate = '';
  maxDate = new Date();
  description;
  maxChars = 300;
  maxChars1 = 600;
  searchGoalTxt = '';
  showMicro: boolean = true;
  isEdit = false;
  observationDetails;
  reqId;
  observationForm;
  showEmotion = 0;
  myCommonArrEmotions = [];
  bsConfig = { dateInputFormat: 'MMMM DD, YYYY', 'customTodayClass': 'today', showWeekNumbers: false, }
  pastEmotions1 = [];
  pastEmotions2 = [];
  pastEmotions3 = [];
  pastEmotions4 = [];
  pastEmotions5 = [];
  index;
  emotionDataForIntensity = [];
  reviewId;
  speechType: string = '';
  modalRef;
  enableBtn = false;
  showPdf = 'false';
  showLoader = false;
  showThanks = false;
  reportOneChart: EChartOption;
  BarChartReport: any = [];
  graphData: any = [];
  tableData: any = [];
  imgUrlData: any;
  activeTab:any;
  currentPage:any;
  constructor(
    public service: VoiceRecognitionServiceService,
    private admin: AdminService,
    private route: ActivatedRoute,
    private modalService1: BsModalService,
    private formBuilder: FormBuilder,
    private location: Location,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams.detail) {
      this.backTodetail = this.route.snapshot.queryParams.detail;
      this.route.queryParams.subscribe(params => {
        if (params['tab'])
          this.activeTab = Number(params['tab']);
         this.currentPage = Number(params['page']) || 1
      });
    }
    if (this.route.snapshot.queryParams.id) {
      this.reviewId = this.route.snapshot.queryParams.id;
    } else {
      this.reqId = this.route.snapshot.params.id;
    }
    if (this.reqId) {
      this.getDeatilByToken();
    } else if (this.reviewId) {
      this.getDeatil();
      this.getGraphDetail();
    }
    this.observationForm = this.formBuilder.group({
      title: [{ value: '', disabled: true }, Validators.required],
      category: [{ value: '', disabled: true }, Validators.required],
      description: [{ value: '', disabled: true }, Validators.required],
      comment1: ['', Validators.required],
      comment2: ['', Validators.required],
      comment3: ['', Validators.required],
      comment4: ['', Validators.required],
      comment5: ['', Validators.required],
    });
  }

  getDeatil() {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    this.admin.getData(`${this.baseUrl}/api/v2/observations/` + this.reviewId, params)
      .subscribe(async (res) => {
        this.observationDetails = res['data']
        this.observationForm.patchValue(res.data);
        if (this.observationDetails.deadline) {
          let eventDate = moment.utc(this.observationDetails.deadline).local().format('MMM DD, YYYY');
          this.pastDate = eventDate;
        }
      })
  }

  getDeatilByToken() {
    this.admin.getDatas(`${this.baseUrl}/api/v2/observation_respondents/` + this.reqId)
      .subscribe(async (res) => {
        this.observationDetails = res['data']
        this.observationForm.patchValue(res.data);
        this.observationForm.controls.category.patchValue(res.data.category);
        this.observationDetails.form.filter(question => {
          question.title = question.title.replace('{{user_name}}', this.titleCaseWord(this.observationDetails?.subject?.first_name) + ' ' + this.titleCaseWord(this.observationDetails?.subject?.last_name));
        })
        if (this.observationDetails.deadline) {
          let eventDate = moment.utc(this.observationDetails.deadline).local().format('MMM DD, YYYY');
          this.pastDate = eventDate;
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
        this.admin.errorAlert1('', error.error.message);
      })
  }

  titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  handleEmotionData(data: any) {
    if (data[0] == -1) {
      this.showEmotion = 0;
    } else if (data[0] == -2) {
      this.showEmotion = 1;
    } else {
      this.showEmotion = data[0];
      this.emotionDataForIntensity = data[1];
    }
  }

  addEmotion(i) {
    this.index = i;
    if (i == 1) {
      this.myCommonArrEmotions = this.pastEmotions1;
    }
    if (i == 2) {
      this.myCommonArrEmotions = this.pastEmotions2;
    }
    if (i == 3) {
      this.myCommonArrEmotions = this.pastEmotions3;
    }
    if (i == 4) {
      this.myCommonArrEmotions = this.pastEmotions4;
    }
    if (i == 5) {
      this.myCommonArrEmotions = this.pastEmotions5;
    }
    this.showEmotion = 1;
  }

  handleEmotionDataValues(data: any) {
    if (data[0] == -1) {
      this.showEmotion = 0;
    } else if (data[0] == -2) {
      this.myCommonArrEmotions = data[1];
      this.showEmotion = 1;
    } else {
      this.showEmotion = data[0];
      if (this.index == 1) {
        this.pastEmotions1 = data[1];
        this.pastEmotions1.forEach((e) => {
          e['checked'] = true;
        })
      }
      if (this.index == 2) {
        this.pastEmotions2 = data[1];
        this.pastEmotions2.forEach((e) => {
          e['checked'] = true;
        })
      }
      if (this.index == 3) {
        this.pastEmotions3 = data[1];
        this.pastEmotions3.forEach((e) => {
          e['checked'] = true;
        })
      }
      if (this.index == 4) {
        this.pastEmotions4 = data[1];
        this.pastEmotions4.forEach((e) => {
          e['checked'] = true;
        })
      }
      if (this.index == 5) {
        this.pastEmotions5 = data[1];
        this.pastEmotions5.forEach((e) => {
          e['checked'] = true;
        })
      }
      this.myCommonArrEmotions = data[1];
    }
  }

  removeEmotion(i) {
    if (i == 1) {
      this.pastEmotions1 = this.pastEmotions1.filter((e) => { return e.checked == true });
      this.myCommonArrEmotions = this.pastEmotions1;
    }
    if (i == 2) {
      this.pastEmotions2 = this.pastEmotions2.filter((e) => { return e.checked == true });
      this.myCommonArrEmotions = this.pastEmotions2;
    }
    if (i == 3) {
      this.pastEmotions3 = this.pastEmotions3.filter((e) => { return e.checked == true });
      this.myCommonArrEmotions = this.pastEmotions3;
    }
    if (i == 4) {
      this.pastEmotions4 = this.pastEmotions4.filter((e) => { return e.checked == true });
      this.myCommonArrEmotions = this.pastEmotions4;
    }
    if (i == 5) {
      this.pastEmotions5 = this.pastEmotions5.filter((e) => { return e.checked == true });
      this.myCommonArrEmotions = this.pastEmotions5;
    }
  }
  opacityValue(value) {
    let number = parseInt(value);
    if (number > 9) {
      return "1";
    }
    else if (number < 1) {
      return "0.1";
    } else {
      return "0." + number;
    }
  }
  getLength(i) {
    if (i == 1) {
      return this.pastEmotions1?.length;
    }
    if (i == 2) {
      return this.pastEmotions2?.length;
    }
    if (i == 3) {
      return this.pastEmotions3?.length;
    }
    if (i == 4) {
      return this.pastEmotions4?.length;
    }
    if (i == 5) {
      return this.pastEmotions5?.length;
    }
  }

  submit() {
    this.admin.showLoader = true;
    const data = {
      response: this.prepareddataforapi()
    }
    this.admin.patchData(this.baseUrl + '/api/v2/observation_respondents/' + this.reqId, data).subscribe(res => {
      this.showThanks = true;
      this.admin.showLoader = false;
      this.admin.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">Successfully submitted</span>', '');
      this.clearForm();
    })
  }

  clearForm() {
    this.observationForm.reset();
    this.observationForm.patchValue(this.observationDetails);
    this.observationForm.controls.category.patchValue(this.observationDetails.category);
    this.pastEmotions1 = [];
    this.pastEmotions2 = [];
    this.pastEmotions3 = [];
    this.pastEmotions4 = [];
    this.pastEmotions5 = [];
  }

  prepareddataforapi() {
    let data;
    let dataTobeSend = [];
    this.observationDetails.form.filter((question, i) => {
      i = i + 1;
      let observation_response_emotions;
      let observation_response_emotions_attributes = [];
      if (i == 1) {
        this.pastEmotions1.filter(item => {
          observation_response_emotions = {
            emotion_id: item.emotion_id,
            intensity: item?.intensity
          }
          observation_response_emotions_attributes.push(observation_response_emotions);
        })
      }
      if (i == 2) {
        this.pastEmotions2.filter(item => {
          observation_response_emotions = {
            emotion_id: item.emotion_id,
            intensity: item?.intensity
          }
          observation_response_emotions_attributes.push(observation_response_emotions);
        })
      }
      if (i == 3) {
        this.pastEmotions3.filter(item => {
          observation_response_emotions = {
            emotion_id: item.emotion_id,
            intensity: item?.intensity
          }
          observation_response_emotions_attributes.push(observation_response_emotions);
        })
      }
      if (i == 4) {
        this.pastEmotions4.filter(item => {
          observation_response_emotions = {
            emotion_id: item.emotion_id,
            intensity: item?.intensity
          }
          observation_response_emotions_attributes.push(observation_response_emotions);
        })
      }
      if (i == 5) {
        this.pastEmotions5.filter(item => {
          observation_response_emotions = {
            emotion_id: item.emotion_id,
            intensity: item?.intensity
          }
          observation_response_emotions_attributes.push(observation_response_emotions);
        })
      }
      data = {
        observation_form_id: question?.id,
        description: this.observationForm?.controls['comment' + (i)]?.value,
        observation_response_emotions_attributes: observation_response_emotions_attributes
      }
      dataTobeSend.push(data);
    })
    return dataTobeSend;
  }

  replaceWithName(name) {
    return name.replace('{{user_name}}', this.titleCaseWord(this.observationDetails?.subject?.first_name) + ' ' + this.titleCaseWord(this.observationDetails?.subject?.last_name));
  }

  public downloadAsPDF() {
    window.open(`${this.baseUrl}/api/v1/test/download?id=` + this.reviewId + '&access_token=' + localStorage.getItem('access_token'), "_blank");
    // let params = new HttpParams();
    // params = params.append('id', this.reviewId);
    // params = params.append('access_token', localStorage.getItem('access_token'));
    // this.admin.getData(`${this.baseUrl}/api/v1//test/download`, params)
    //   .subscribe(async (res) => {
   
    //   })

  }

  public getUrl() {
    let data = {
      id: this.reviewId,
      access_token: localStorage.getItem('access_token'),
      image: this.imgUrlData
    }
    this.admin.postData(`${this.baseUrl}/api/v1/test/upload_graph`, data)
      .subscribe(async (res) => {

      })
  }


  openSpeechModal(template: TemplateRef<any>, type) {
    this.service.stop();
    this.service.text = '';
    this.enableBtn = true;
    if (this.service.init()) {
      this.modalRef = this.modalService1.show(template, {
        class: 'modal-dialog w-6 speech_modal',
        backdrop: 'static',
      });
      this.speechType = type;
    }
  }

  complete(type) {
    this.modalRef.hide();
    this.observationForm?.controls['comment' + (type)].patchValue(this.service.text.substring(0, 300));
    this.service.text = '';
    let audio = new Audio();
    audio.src = "assets/audio/music_marimba_confirm.mp3";
    audio.load();
    audio.play();
  }

  closeSpeech() {
    this.modalRef.hide();
    this.service.stop();
    this.showMicro = true;
    this.service.text = '';
  }


  startService(type) {
    this.showMicro = false;
    this.service.text = this.observationForm?.controls['comment' + (type)].value;
    this.service.start();
  }

  stopService() {
    this.enableBtn = false;
    this.service.stop();
    this.showMicro = true;
  }

  back() {
    this.router.navigate(['observations/' + this.reviewId]);
  }
  goToBack() {
    if (this.backTodetail) {
      // this.router.navigate(['observations/' + this.reviewId]);
      this.router.navigate(['/observations/' +this.reviewId], { queryParams: { 'tab': this.activeTab, 'page': this.currentPage } })
    } else {
      if (this.observationDetails.form_status == 0) {
        this.router.navigate(['/observations'], { queryParams: { 'tab': this.observationDetails.form_status } })
      } else if (this.observationDetails.form_status == 1) {
        this.router.navigate(['/observations'], { queryParams: { 'tab': this.observationDetails.form_status } })
      } else if (this.observationDetails.form_status == 2) {
        this.router.navigate(['/observations'], { queryParams: { 'tab': this.observationDetails.form_status } })
      }
    }
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }

  showChart(data) {
    this.reportOneChart = {
      responsive: true,
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '15px',
        right: '30px',
        bottom: '5%',
        containLabel: true
      },
      legend: {
        padding: [0, 0, 0, 15],
        x: 'left',
        data: ['', 'Emotions Experienced',],
        icon: 'circle'
      },
      xAxis: {
        type: 'category',
        data: data.title_arr,
      },
      yAxis: [{ max: this.BarChartReport[0].value }, {}],
      series: [
        {
          name: '',
          type: 'bar',
          yAxisIndex: 0,
          tooltip: {
            show: false
          },
          data: this.BarChartReport
        },
        {
          name: 'Emotions Experienced',
          type: 'line',
          smooth: true,
          lineStyle: {
            color: '#000000',
            width: 2,
          },
          itemStyle: {
            borderWidth: 2,
            borderColor: '#000000',
            color: 'black'
          },
          tension: 2,
          label: {
            show: true,
            position: 'top',
            textStyle: {
              fontSize: 15,
              color: '#000000'
            }
          },
          yAxisIndex: 0,
          tooltip: {
            show: true
          },
          data: data.count_arr
        }
      ]
    }
  }

  public downloadAsPDFs(reporttype) {
    let screenSize = window.innerWidth
    let chartDiv;
    let pdfname;
    if (reporttype == 1) {
      chartDiv = <HTMLImageElement>document.querySelector('#report1');
      pdfname = "Emotions Experienced Between Two Dates"
    }
    let divHeight = chartDiv.clientHeight;
    let divWidth = chartDiv.clientWidth;
    let totalHeight = divHeight + 200;
    let PdfHight;
    if (screenSize < 1600) {
      PdfHight = totalHeight
    } else {
      PdfHight = 500;
    }

    html2canvas(chartDiv, {
      width: divWidth, height: totalHeight,
      onclone: function (clonedDoc) {
        if (screenSize < 1600) {
          PdfHight = totalHeight > 600 ? (totalHeight) : 600;
        } else {
          PdfHight = totalHeight > 600 ? (totalHeight) : 600;
        }
        PdfHight = totalHeight > 870 ? (totalHeight) : 870;
      }, allowTaint: true
    }).then((canvas) => {
      this.imgUrlData = canvas.toDataURL('image/png');

      if (this.observationDetails?.graph_url == '') {
        this.getUrl();
      }
    });
  }

  getGraphDetail() {
    let params = new HttpParams();
    params = params.append('access_token', localStorage.getItem('access_token'));
    params = params.append('id', this.reviewId);
    this.admin.getData(`${this.baseUrl}/api/v2/observations/observation_graph`, params)
      .subscribe(async (res) => {
        this.graphData = res['data']
        this.tableData = res.data.table_data;

        this.tableData.filter(item => {
          item.sort((a, b) => b.count - a.count)
          item['total'] = 0;
        })
        this.tableData.filter(item => {
          item.filter(data => {
            if (data?.count)
              item['total'] = item['total'] + Number(data?.count);
          })
        })
        this.BarChartReport = [];
        let bigValue = Math.max.apply(Math, this.graphData.count_arr.map(function (o) { return o; }));
        for (let i = 0; i < this.graphData.color_arr.length; i++) {
          this.BarChartReport.push({
            value: bigValue ? (bigValue) : 0,
            itemStyle:
            {
              color: this.graphData.color_arr[i]
            }
          })
        }
        this.showChart(this.graphData);
        setTimeout(() => {
          this.downloadAsPDFs(1);
        }, 2000);
      })
  }

}
