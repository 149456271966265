<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container">
        <div class="container">
            <div class="profileWrap">
                <div class="logo">
                    <a ><img src="assets/images/Growthdrivers.svg"></a>
                </div>
                <h2>Reset Password</h2>
                <div class="form-box">
                    <p>Please complete the fields below to reset your password.</p>


                    <form [formGroup]="form" (ngSubmit)="resetPassword()">
                        <div class="form-group">
                            <label class="field_name">New Password</label>
                            <input [type]="passwordType" class="form-control" formControlName="password" placeholder="New Password*" autocomplete="off" [(ngModel)]="password">
                            <a  class="showPass" (click)="togglePassword('new')">
                                <img [src]="passwordShown == false? 'assets/images/eye-off-2.svg' :'assets/images/ic_eye_show_red.svg' " tabindex=3 /></a>
                            <div class="text-danger" *ngIf="form.controls['password'].hasError('required') && showError">
                                *Please enter password.
                            </div>
                            <div class="warnings pl-1 ng-star-inserted" *ngIf="showError&&form.controls.password.errors?.minlength || showError&&form.controls.password.errors?.maxlength || form.controls['password'].hasError('pattern') && showError"> Password must be 8 to 16 alphanumeric upper and lower case characters in length and contain at least one special character.
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="field_name">Confirm Password</label>
                            <input [type]="confirmPasswordType" class="form-control" formControlName="confirmPassword" placeholder="Confirm Password*" autocomplete="off" [(ngModel)]="confirmPassword">
                            <a  class="showPass" (click)="togglePassword('confirm')">
                                <img [src]="ConfirmPasswordShown == false? 'assets/images/eye-off-2.svg' :'assets/images/ic_eye_show_red.svg' " tabindex=3 /></a>
                            <div class="text-danger" *ngIf="form.controls['confirmPassword'].hasError('required') && showError">
                                *Please enter confirm password.
                            </div>
                            <div class="text-danger" *ngIf="form.controls['confirmPassword'].hasError('mustMatch') && showError">
                                *New Password and Confirm Password values must be the same.
                            </div>
                        </div>
                        <div class="form-group mb-0">
                            <button type="submit" [class.disable]="!form.controls['confirmPassword'].value || !form.controls.password.value" [disabled]="!form.controls['confirmPassword'].value || !form.controls.password.value" class="btn btn-primary reset_btn"><span></span>RESET PASSWORD</button>
                        </div>
                        <div class="backbtn">
                            <a routerLink="/login" class="backTologin"> Go Back to Login</a>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>

    <div class="footerWrap">
        <div class="copyright">
            <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R2.0.0</span>
            <p>&copy; 2021 - 2023 Guidewise</p>
        </div>
    </div>
</div>