import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { GeneratePasswordComponent } from './generate-password/generate-password.component';
import { LinkExpiredComponent } from './link-expired/link-expired.component';
import { MessageSuccessComponent } from './message-success/message-success.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { ContactusComponent } from './contactus/contactus.component';


import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { LoginheaderComponent } from './loginheader/loginheader.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { PrivacyPolicyViewComponent } from './privacy-policy/privacy-policy-view/privacy-policy-view.component';
import { TermsOfUseComponent } from './terms-conditions/terms-of-use/terms-of-use.component';
import { UserObservationsComponent } from './user-observations/user-observations.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SharedModule } from '../shared/shared.module';
import { PdfDownLoadComponent } from './user-observations/pdf-down-load/pdf-down-load.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { FeedbackComponent } from './feedback/feedback.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, CreateProfileComponent, ResetPasswordComponent, GeneratePasswordComponent, LinkExpiredComponent, MessageSuccessComponent,TermsConditionsComponent,PrivacyPolicyComponent,FaqComponent, EnrollmentComponent, ContactusComponent, LoginheaderComponent, PrivacyPolicyViewComponent, TermsOfUseComponent, UserObservationsComponent, PdfDownLoadComponent, FeedbackComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    SharedModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {       
        // siteKey: '6Lec6WkfAAAAAIQw01QnaFELbHUMediqqL5hh2rR',
        siteKey: '6Le1KVEmAAAAAGMc1B3E6zV7V9NnUZZgebj0QAnN',
      } as RecaptchaSettings,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [PrivacyPolicyViewComponent,TermsOfUseComponent]
})
export class LoginModule { }
