import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { apiEndPoint } from '../../shared/apiEndPoints';
import { AdminService } from '../../services/admin.service';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {

  subscription: Subscription;
  createProfileForm: FormGroup = null;
  firstName: string;
  lastName: string;
  userName: string;
  avatarId: number = 3;
  selectedColorId: number;
  jobTitle: string;
  job_fun: string;
  isValidFirstName = false;
  isValidLastName = false;
  userData: any;
  color1 = false;
  color2 = false;
  color3 = false;
  color4 = false;
  color5 = false;
  selectedColorVal: string;

  isAnyAvatarSelected = false;
  isAnyColorSelected = false;

  avatar1 = false;
  avatar2 = false;
  avatar3 = false;
  avatar4 = false;

  firstNameErrorMsg: string;
  lastNameErrorMsg: string;
  userNameError: string;
  roleTypeErrorMsg:string;
  jobTypeErrorMsg:string;

  regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  jobTitles = [
    'Founder/Owner',
    'Executive',
    'Partner',
    'Senior Manager',
    'Manager',
    'Associate',
    'Analyst',
    'Assistant',
    'Other'
  ]
  job_functionList = [];

  constructor(private readonly authService: AuthService, private location: LocationStrategy,
    private readonly route: Router, private api: AdminService) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.job_functionList = this.api?.job_fun;
    this.avatar3 = true;
    this.color4 = true;
    this.selectedColorId=3;
    this.avatarId=3;
    this.userData = JSON.parse(localStorage.getItem('gdUserInfo'))
    this.createProfileForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
      userName: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
      roleType: new FormControl('', Validators.required),
      job_fun: new FormControl('', Validators.required),
    });
    this.firstName = this.userData.first_name;
    this.lastName = this.userData.last_name;
    this.userName = this.userData.user_name;
  }

  toggleColor(id: number, colorVal: string) {
    switch (id) {
      case 0:
        this.color1 = true;
        this.isAnyColorSelected = true;
        this.color2 = this.color3 = this.color4 = this.color5 = false;
        break;

      case 1:
        this.color2 = true;
        this.isAnyColorSelected = true;
        this.color1 = this.color3 = this.color4 = this.color5 = false;
        break;

      case 2:
        this.color3 = true;
        this.isAnyColorSelected = true;
        this.color1 = this.color2 = this.color4 = this.color5 = false;
        break;

      case 3:
        this.color4 = true;
        this.isAnyColorSelected = true;
        this.color1 = this.color2 = this.color3 = this.color5 = false;
        break;

      case 4:
        this.color5 = true;
        this.isAnyColorSelected = true;
        this.color1 = this.color2 = this.color3 = this.color4 = false;
        break;
    }
    this.selectedColorId = id;
    this.selectedColorVal = colorVal;

  }

  toggleAvatar(id: number) {
    this.avatarId = id;
    switch (id) {
      case 1:
        this.avatar1 = true;
        this.isAnyAvatarSelected = true;
        this.avatar2 = this.avatar3 = this.avatar4 = false;
        break;

      case 2:
        this.avatar2 = true;
        this.isAnyAvatarSelected = true;
        this.avatar1 = this.avatar3 = this.avatar4 = false;
        break;

      case 3:
        this.avatar3 = true;
        this.isAnyAvatarSelected = true;
        this.avatar2 = this.avatar1 = this.avatar4 = false;
        break;

      case 4:
        this.avatar4 = true;
        this.isAnyAvatarSelected = true;
        this.avatar1 = this.avatar2 = this.avatar3 = false;
        break;
    }

  }

  submitForm() {
    //this.firstName = this.createProfileForm.value.firstName;
    //this.lastName = this.createProfileForm.value.lastName;
    this.jobTitle = this.createProfileForm.value.roleType;
    this.job_fun = this.createProfileForm.value.job_fun;
    this.firstNameErrorMsg = '';
    this.lastNameErrorMsg = '';
    this.userNameError = '';
    this.roleTypeErrorMsg='';
    this.jobTypeErrorMsg='';
    if (this.firstName === '') {
      this.firstNameErrorMsg = '*Please enter first name.';
    }
    if (this.lastName === '') {
      this.lastNameErrorMsg = '*Please enter last name.';
    }
    if (this.userName === '') {
      this.userNameError = '*Please enter Username.';
    }
    if ((this.firstName.length > 0 && this.firstName.length < 1) || this.firstName.length > 20) {
      this.firstNameErrorMsg = 'First name needs to be between 1-20 characters.'
    }
    if ((this.lastName.length > 0 && this.lastName.length < 1) || this.lastName.length > 20) {
      this.lastNameErrorMsg = 'Last name needs to be between 1-20 characters.';
    } else if (this.firstName.match(this.regex) || this.firstName.match(/\d+/g)) {
      // this.firstNameErrorMsg = 'Only alphabets, no special characters allowed.'
    } else if (this.lastName.match(this.regex) || this.lastName.match(/\d+/g)) {
      // this.lastNameErrorMsg = 'Only alphabets, no special characters allowed.';
    }
    if (this.jobTitle === '') {
      this.roleTypeErrorMsg= '*Please select job title.';
    }
    if (this.job_fun === '') {
      this.jobTypeErrorMsg= '*Please select job function.';
    }
    
    if (this.createProfileForm.valid) {
      this.createProfile(this.firstName, this.lastName, this.jobTitle,this.userName);
    }
  }

  createProfile(firstName: string, lastName: string, jobTitle: string,userName): void {
    this.authService.setProfileStatus(true);
    localStorage.setItem('rememberMeGd', this.authService.rememberMe);
    const profileObj = {
      access_token: localStorage.getItem('access_token'),
      full_name: `${firstName} ${lastName}`,
      first_name: firstName,
      last_name: lastName,
      user_name: userName,
      profile_status: '1',
      //avatar: this.avatarId,
      avatar_id: this.avatarId,
      avatar_color: this.selectedColorId,
      job_title: jobTitle,
      job_function: this.job_fun,
      role: '1'
    };
    this.api.postData(apiEndPoint.CREATE_PROFILE, profileObj).subscribe((e) => {
      if (e.status == 200) {
        localStorage.setItem('gdUserInfo', JSON.stringify(e.data));
        this.route.navigate(['/dashboard']);
      }else {
        this.api.errorToast('<i class="mdi bigSize mdi-alert-circle-outline"></i> <span class="font_size">' + e.message + '</span>', '');
      }
    },
      (error: HttpErrorResponse) => {
        console.log(error);
      })
  }
}



