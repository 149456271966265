<div *ngIf="showEmotion===0" class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader" *ngIf="reqId">
        <div class="container">
            <app-loginheader></app-loginheader>
        </div>
    </div>
    <div *ngIf="!showThanks">
        <form [formGroup]="observationForm">
            <div class="wrapper new-entry" id="new-entry">
                <div class="new-entry-container">
                    <div class="entry-data-wrap no-header-top" *ngIf="reviewId">
                        <h4 class="">
                            <a class="" >
                                <span class="cross-main" (click)="goToBack()">
                                    <img class="cross1" src="assets/images/close-icon-black.svg">
                                </span>
                                <span class="heading noHeader" (click)="goToBack()" style="cursor: pointer;"> 
                                    Observation for 
                                    <span class="capitalize">
                                        {{observationDetails?.subject?.first_name}}
                                        {{observationDetails?.subject?.last_name}}
                                    </span>
                                </span>
                                <span class="created-by grey date no-header">
                                    Created by: {{observationDetails?.user?.first_name}} {{observationDetails?.user?.last_name}}
                                </span>
                            </a>
                        </h4>
                    </div>
                    <div class="entry-data-wrap" [ngClass]="reviewId ? 'apply' : '' ">
                        <h4 class="mb-4" style="margin-top: -20px;" *ngIf="reqId">
                            <a>
                                <span class="cross-main" *ngIf="reqId">
                                    <img class="cross" src="">
                                </span>
                                <!-- (click)="reviewId ? goToBack() : reqId ? goToLogin() : '' " -->
                                <span class="heading"> 
                                    Observation for 
                                    <span class="capitalize">
                                        {{observationDetails?.subject?.first_name}}
                                        {{observationDetails?.subject?.last_name}}
                                    </span>
                                </span>    
                                <span class="created-by grey date">
                                    Created by: {{observationDetails?.user?.first_name}} {{observationDetails?.user?.last_name}}
                                </span>
                                
                            </a>
                        </h4>
                        <div class="row">
                            <div class="col-md-4 col-6-set">
                                <div class="commonRow ">
                                    <h5 class="entry-label">Title</h5>
                                    <div class="form-group">
                                        <input formControlName="title" type="text"
                                            style="background-color: #cfcdcd !important;" placeholder="Enter title..."
                                            class="form-control txt_right_pad" id="title" maxlength="300">
                                        <!-- <i class="mdi mdi-microphone des-micro"></i> -->
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-md-3 col-6-set">
                                <div class="commonRow ">
                                    <h5 class="entry-label">Category</h5>
                                </div>
                                <input formControlName="category" type="text" style="background-color: #cfcdcd !important;"
                                    placeholder="Enter title..." class="form-control txt_right_pad" id="title"
                                    maxlength="300">
                            </div>
    
                            <div class="col-md-5 col-12-set">
                                <div class="commonRow ">
                                    <h5 class="entry-label">Deadline</h5>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                        <div class="date">
                                            <input disabled type="text" autocomplete="off"
                                                style="background-color: #cfcdcd !important;" [value]="pastDate"
                                                class="form-control disable" placeholder="Select date..."
                                                placement="bottom">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="commonRow ">
                                        <h5 class="entry-label">Description</h5>
                                        <!-- <span
                                            class="{{observationForm?.controls?.description?.value?.length== maxChars1 ? ('txtRed'):''}} charCount">{{observationForm?.controls?.description?.value?.length}}/{{maxChars1}}</span> -->
                                        <textarea style="background-color: #cfcdcd !important;"
                                            formControlName="description" class="form-control disable" id="desc"
                                            maxlength="600" placeholder="Describe the event…" rows="5"
                                            (keyup)="service.text = description"></textarea>
                                        <!-- <i class="mdi mdi-microphone des-micro"></i> -->
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!reviewId" class="col-md-12">
                                <div class="form-group mb-1">
                                    <div class="commonRow ">
                                        <h5 class="entry-label">Instructions:</h5>
                                    </div>
                                    <p class="color_black">
                                        Thank you for providing feedback for {{observationDetails?.subject?.first_name}}
                                        {{observationDetails?.subject?.last_name}}<span *ngIf="observationDetails?.subject?.first_name">.</span> 
                                        To submit your feedback, please respond
                                        to the following questions. Please note that your feedback for
                                        {{observationDetails?.subject?.first_name}}
                                        {{observationDetails?.subject?.last_name}} will be completely anonymous and
                                        confidential. Aside from yourself, nobody will know who provided the feedback
                                        details within your submission. So, please remember to respond to the following
                                        questions as candidly and constructively as possible. If you have any questions or
                                        need additional assistance, please reach out to our support team at
                                        support@guidewise.io.
                                    </p>
                                </div>
                            </div>
                            <div *ngIf="reviewId" class="subjectProfile">
                                <h5>Subject Profile</h5>
                                <div class="info-box">
                                    <div class="profile_info">
                                        <h6>First Name:</h6>
                                        <p>{{observationDetails?.subject?.first_name}}</p>
                                    </div>
                                    <div class="profile_info">
                                        <h6>Last Name:</h6>
                                        <p>{{observationDetails?.subject?.last_name}}</p>
                                    </div>
                                    <div class="profile_info">
                                        <h6>Username:</h6>
                                        <p>{{observationDetails?.subject?.user_name}}</p>
                                    </div>
                                    <div class="profile_info">
                                        <h6>Work Email:</h6>
                                        <p class="smallLetter">{{observationDetails?.subject?.email}}</p>
                                    </div>
                                    <div class="profile_info">
                                        <h6>Job Function:</h6>
                                        <p>{{observationDetails?.subject?.job_function}}</p>
                                    </div>
                                    <div class="profile_info">
                                        <h6>Job Title:</h6>
                                        <p>{{observationDetails?.subject?.job_title}}</p>
                                    </div>
                                    <div class="profile_info">
                                        <h6>Total Points:</h6>
                                        <p>{{observationDetails?.subject?.points}}</p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="reviewId" class="bottom_cal">
                                <div class="info">
                                    <h5>
                                        Avg. Intensity Selected
                                    </h5>
                                    <span class="boldText">
                                        {{observationDetails?.avg_intensity_reported}}/10
                                    </span>
                                </div>
                                <div class="info">
                                    <h5>
                                        Top 5 Avg. Intensities Experienced
                                    </h5>
                                    <div *ngFor="let item of observationDetails?.avg_tntensities_per_emotion;let i = index;"
                                        class="row table_form" [class.round]="i==0">
                                        <div class="col-8 field">
                                            {{item?.name}}
                                        </div>
                                        <div class="col-4 value">{{item?.avg | number : '1.2-2'}}</div>
                                    </div>
                                </div>
                                <div class="info">
                                    <h5>
                                        Emotions Experienced
                                    </h5>
                                    <span class="boldText">
                                        {{observationDetails?.emotion_experienced_count}}
                                    </span>
                                </div>
                                <div class="info">
                                    <h5>
                                        Top 5 Emotions Experienced
                                    </h5>
                                    <div *ngFor="let item of observationDetails?.top_five_emotions_experienced;let i =index;"
                                        class="row table_form" [class.round]="i==0">
                                        <div class="col-8 field">
                                            {{item?.name}}
                                        </div>
                                        <div class="col-4 value">{{item?.count}}</div>
                                    </div>
                                </div>
                                <div class="info">
                                    <h5>
                                        Top 5 Emotion Columns Selected
                                    </h5>
                                    <div *ngFor="let item of observationDetails?.top_emotional_solumns_selected"
                                        class="row square table_form" [ngStyle]="{'background':item?.color_code}">
                                        <div class="col-8 field">
                                            {{item?.title}}
                                        </div>
                                        <div class="col-4 value">{{item?.count}}</div>
                                    </div>
                                    <!-- <div class="row square bg-green table_form">
                                        <div class="col-8 field">
                                            Columns
                                        </div>
                                        <div class="col-4 value">40</div>
                                    </div> -->
                                    <!-- <div class="row square bg-cyan table_form">
                                        <div class="col-8 field">
                                            Columns
                                        </div>
                                        <div class="col-4 value">40</div>
                                    </div>
                                    <div class="row square bg-purple table_form">
                                        <div class="col-8 field">
                                            Columns
                                        </div>
                                        <div class="col-4 value">40</div>
                                    </div>
                                    <div class="row square bg-teal table_form">
                                        <div class="col-8 field">
                                            Columns
                                        </div>
                                        <div class="col-4 value">40</div>
                                    </div>
                                    <div class="row square bg-red table_form">
                                        <div class="col-8 field">
                                            Columns
                                        </div>
                                        <div class="col-4 value">40</div>
                                    </div> -->
                                </div>
                            </div>
                            <div *ngIf="reqId" class="w-100">
                                <div *ngFor="let data of observationDetails?.form;let i = index;" class="col-md-12">
                                    <div class="form-group">
                                        <div class="commonRow ">
                                            <h5 class="entry-label question_label">{{i + 1}}) {{data?.title}}</h5>
                                        </div>
                                        <div class="commonRow feedbackrow d-flex">
                                            <div class="col-6 p-0 mr-5">
                                                <h5 class="entry-label">Emotions Selected ({{getLength(i+1)}})</h5>
                                                <div class="coverLayers">
                                                    <div *ngIf="getLength(i+1)>0"
                                                        class="box past custom-padding scroll_custom padding_b">
                                                        <ng-container
                                                            *ngFor="let data of (i==0 ? pastEmotions1 : i==1 ? pastEmotions2: i == 2 ? pastEmotions3 : i == 3 ? pastEmotions4 : pastEmotions5)">
                                                            <div *ngIf="data.checked" class="tags">
                                                                <span class="tagBg"
                                                                    [ngStyle]="{'background':data.color_code, 'opacity': opacityValue(data.intensity)}"></span>
                                                                <span class="float-left">{{data.name}}
                                                                    ({{data.intensity}})</span>
                                                                <span class="float-right close-icon">
                                                                    <!-- {{data.day}}D {{data.hours}}H
                                                                    {{data.minutes}}M -->
                                                                    <a style="margin: 3px 10px;" class="close-icon-black"
                                                                        (click)="data.checked = !data.checked; removeEmotion(i+1)">
                                                                        <img style="cursor: pointer;"
                                                                            src="assets/images/close-icon-black.svg"
                                                                            class="mCS_img_loaded">
                                                                    </a>
                                                                </span>
                                                                <div class="clear"></div>
                                                            </div>
                                                        </ng-container>
    
                                                    </div>
                                                    <p><textarea *ngIf="getLength(i+1) == 0" disabled #pastBelief
                                                            placeholder="Select emotions..."
                                                            class="gd-textarea form-control"></textarea></p>
                                                    <a class="add_emotions clickable Position_css"
                                                        (click)="addEmotion(i+1)">Add
                                                        Emotions</a>
                                                    <!-- <a class="add_emotions clickable Position_css" [class.add-emotion-button]="pastEmotionError">Add
                                                    Emotions</a> -->
                                                </div>
                                                <!-- <span *ngIf="pastEmotionError" class="warnings">{{pastEmotionErrorMsg}}</span> -->
                                            </div>
                                            <div class="col-6 p-0">
                                                <h5 class="entry-label">Notes </h5>
                                                <span
                                                    class="{{observationForm?.controls['comment'+(i+1)]?.value?.length == 300 ? ('txtRed'):''}} charCount">
                                                    {{observationForm?.controls['comment'+(i+1)]?.value?.length ? observationForm?.controls['comment'+(i+1)]?.value?.length : 0}}/{{'300'}}</span>
                                                <div class="coverLayers">
                                                    <p><textarea #pastBelief maxlength="300"
                                                            [formControlName]="'comment' + (i+1)"
                                                            placeholder="Enter notes...."
                                                            class="gd-textarea form-control"></textarea></p>
                                                    <i class="mdi mdi-microphone des-micro"
                                                        (click)="openSpeechModal(speech,i+1)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100" *ngIf="reviewId">
                                <div class="chartwrap" id="report1">
                                    <div echarts [options]="reportOneChart" class="demo-chart"></div>
                                </div>
                                <div class="border_bottom" *ngFor="let items of observationDetails?.observation_respondents;let index = index;">
                                    <h5 class="fhead">
                                        Submission {{index + 1}}:
                                    </h5>
                                    <div *ngFor="let data of items?.observation_responses;let i = index;" class="col-md-12">
                                        <div class="form-group">
                                            <div class="commonRow ">
                                                <h5 class="entry-label question_label">{{i + 1}})
                                                    {{replaceWithName(data?.observation_form)}}</h5>
                                            </div>
                                            <div class="commonRow feedbackrow d-flex">
                                                <div class="col-6 p-0 mr-5">
                                                    <h5 class="entry-label">Emotions Selected
                                                        ({{data?.observation_response_emotions?.length}})</h5>
                                                    <div class="coverLayers">
                                                        <div *ngIf="data?.observation_response_emotions?.length>0"
                                                            class="box past custom-padding scroll_custom">
                                                            <ng-container
                                                                *ngFor="let datas of data?.observation_response_emotions">
                                                                <div class="tags">
                                                                    <span class="tagBg"
                                                                        [ngStyle]="{'background':datas?.emotion?.color_code, 'opacity': opacityValue(datas?.intensity)}"></span>
                                                                    <span class="float-left">{{datas?.emotion?.name}}
                                                                        ({{datas?.intensity}})</span>
                                                                    <!-- <span class="float-right close-icon">
                                                                        <a  class="close-icon-black"
                                                                            (click)="datas?.checked = !datas?.checked; removeEmotion(i+1)"> <img
                                                                                src="assets/images/close-icon-black.svg"
                                                                                class="mCS_img_loaded">
                                                                        </a></span> -->
                                                                    <div class="clear"></div>
                                                                </div>
                                                            </ng-container>
    
                                                        </div>
                                                        <!-- <p><textarea *ngIf="getLength(i+1) == 0" disabled #pastBelief
                                                                placeholder="What emotions did you experience?"
                                                                class="gd-textarea form-control"></textarea></p>
                                                        <a class="add_emotions clickable Position_css">Add
                                                            Emotions</a> -->
                                                        <!-- <a class="add_emotions clickable Position_css" [class.add-emotion-button]="pastEmotionError">Add
                                                        Emotions</a> -->
                                                    </div>
                                                    <!-- <span *ngIf="pastEmotionError" class="warnings">{{pastEmotionErrorMsg}}</span> -->
                                                </div>
                                                <div class="col-6 p-0">
                                                    <h5 class="entry-label">Notes </h5>
                                                    <span
                                                        class="{{data?.description?.length == 300 ? ('txtRed'):''}} charCount">
                                                        {{data?.description?.length ? data?.description?.length : 0}}/{{'300'}}</span>
                                                    <div class="coverLayers">
                                                        <p><textarea #pastBelief maxlength="300" disabled
                                                                [value]="data?.description" placeholder="Enter Notes...."
                                                                class="gd-textarea form-control"></textarea></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="reqId" class="footer">
                            <button type="button" 
                                [class.disable]="
                                !observationForm.controls.comment1.value && !observationForm.controls.comment2.value && !observationForm.controls.comment3.value && !observationForm.controls.comment4.value && !observationForm.controls.comment5.value &&
                                pastEmotions1?.length == 0 && pastEmotions2?.length == 0 && pastEmotions3?.length == 0 && pastEmotions4?.length == 0 && pastEmotions5?.length == 0 "
                                [disabled]="
                                !observationForm.controls.comment1.value && !observationForm.controls.comment2.value && !observationForm.controls.comment3.value && !observationForm.controls.comment4.value && !observationForm.controls.comment5.value &&
                                pastEmotions1?.length == 0 && pastEmotions2?.length == 0 && pastEmotions3?.length == 0 && pastEmotions4?.length == 0 && pastEmotions5?.length == 0 "
                                class="commonBtn clear_btn" (click)="clearForm()"> clear
                            </button>
                            <!-- <button type="button" 
                            [class.disable]="observationForm?.invalid || pastEmotions1?.length == 0 || pastEmotions2?.length == 0|| pastEmotions3?.length == 0 || pastEmotions4?.length == 0 || pastEmotions5?.length == 0"
                            [disabled]="observationForm?.invalid || pastEmotions1?.length == 0 || pastEmotions2?.length == 0|| pastEmotions3?.length == 0 || pastEmotions4?.length == 0 || pastEmotions5?.length == 0"
                            class="commonBtn clear_btn" (click)="clearForm()"> clear</button> -->
                            <button type="button"
                                [class.disable]="observationForm?.invalid || pastEmotions1?.length == 0 || pastEmotions2?.length == 0|| pastEmotions3?.length == 0 || pastEmotions4?.length == 0 || pastEmotions5?.length == 0"
                                [disabled]="observationForm?.invalid || pastEmotions1?.length == 0 || pastEmotions2?.length == 0|| pastEmotions3?.length == 0 || pastEmotions4?.length == 0 || pastEmotions5?.length == 0"
                                class="commonBtn fill" (click)="submit()">Submit</button>
                        </div>
                        <div *ngIf="reviewId" class="footer">
                            <button type="button" class="commonBtn clear_btn" (click)="back()"> Done</button>
                            <button type="button" class="commonBtn fill" (click)="downloadAsPDF()">Download</button>
                        </div>
    
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="pd-tb" *ngIf="showThanks">
        <div class="container">
            <div class="linksent">
                <h2 class="heading2 mb-3">Thank you for submitting your feedback for <span class="capital">{{observationDetails?.subject?.first_name}}
                    {{observationDetails?.subject?.last_name}}!</span>
                </h2>
                <p class="greyText">If you have any questions or need additional assistance, please reach out to our support team at <a class="hyperlink" href="mailto:support@guidewise.io" target="_blank">support@guidewise.io</a>.</p>
                <div class="social_media">
                    <a href="https://www.facebook.com/guidewisedotio" title="Facebook" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/facebook (1).png">
                    </a>
                    <a href="https://www.instagram.com/guidewisedotio/" title="Instagram" target="_blank"> 
                        <img style="width:25px; height:25px;" src="../../../assets/images/instagram.png">
                    </a>
                    <a href="https://www.linkedin.com/company/guidewisedotio" title="LinkedIn" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/linkedin.png">
                    </a>
                    <a href="https://twitter.com/guidewisedotio" title="Twitter" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/twitter.png"> 
                    </a>
                    <a href="https://www.youtube.com/@guidewisedotio" title="Youtube" target="_blank">
                        <img style="width:25px; height:25px;" src="../../../assets/images/ic_youtube.png"> 
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="footerWrap" *ngIf="reqId">
        <div class="copyright">
            <a class="_logo_a mb-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                <img class="_logo mt-1" src="../../../assets/images/guidewise_primary mark_final.jpg">
            </a>
            <span>Growthdrivers R2.0.0</span>
            <p>&copy; 2021 - 2023 Guidewise</p>
        </div>
    </div>
</div>
<ng-container *ngIf="showEmotion===1 ">
    <app-emotion [existingEmotionData]="myCommonArrEmotions" [notPas]="'true'" (emotionData)="handleEmotionData($event) "></app-emotion>
</ng-container>

<ng-container *ngIf="showEmotion===2 ">
    <app-emotion-intensity [myCommonArrEmotions]="myCommonArrEmotions " [notPas]="'true'" [emotionType]="1 "
        [emotionDataForIntensity]="emotionDataForIntensity " (emotionValues)="handleEmotionDataValues($event) ">
    </app-emotion-intensity>
</ng-container>
<ng-template #speech>
    <div class="modal-header text-center">
        <a class="pointer modal_close_icon" (click)="closeSpeech()"><span><img
                    src="assets/images/close-icon-black.svg"></span>
        </a>
        <h5 class="modal-title"> Click Microphone Button to Begin Voice Capture </h5>
        <h6 class="modal-title modal_title_2"> When finished speaking, click on the microphone button again to finish
            voice capture. </h6>
    </div>
    <!-- Modal body -->
    <div [class.modal_val_pad]="service?.text" class="modal-body modalpoppu modal_content" style="text-align: center;">
        {{service.text}}
    </div>
    <i *ngIf="showMicro" class="mdi mdi-microphone des-micro des-micro-modal" (click)="startService(speechType)"></i>
    <i *ngIf="!showMicro" class="mdi mdi-microphone des-micro des-micro-modal" (click)="stopService()"></i>
    <div *ngIf="!showMicro" class="ripple"></div>
    <div class="footerwrap_modal">
        <button [class.disable]="enableBtn" [disabled]="enableBtn" class="commonBtn button_padding modal_btn clear_btn"
            (click)="service.text = '';enableBtn = true;"> CLEAR </button>
        <button [class.disable]="enableBtn" [disabled]="enableBtn"
            class="commonBtn button_padding modal_btn modal_continue" (click)="complete(speechType)"> COMPLETE </button>
    </div>
</ng-template>


<ng-container *ngIf="showPdf == 'true'">
    <app-pdf-down-load *ngIf="observationDetails" [showPdfData]="observationDetails"></app-pdf-down-load>
</ng-container>
