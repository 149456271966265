<div class="row align-items-center">
    <div class="col-6">
        <a  routerLink="/" class="logo">
            <img src="assets/images/Growthdrivers.svg" />
        </a>
    </div>
    <div class="col-6">
        <ul class="navbar">
            <li><a [class.active]="activeClass==1"  (click)="gotoTerms()">Terms of Use</a></li>
            <li><a [class.active]="activeClass==2"  (click)="gotoPrivacy()">Privacy Policy</a></li>
            <li><a [class.active]="activeClass==3"  (click)="gotoFaq()">FAQs</a></li>
            <li><a [class.active]="activeClass==4"  routerLink="/contactus">Contact Us</a></li>
            <li><a [class.active]="activeClass==5"  routerLink="/feedback">Feedback</a></li>
        </ul>
    </div>
</div>