<div class="wrapper add-past-emo">
    <div class="new-entry-container">
        <div class="title-bar">
            <h4><a (click)="goBack()" class="backArrow"> {{pageTitle}}
                </a></h4>
            <p class="sub_title">
                <span>Select at least one emotion.</span>
            </p>
        </div>

        <div class="whiteBox">
            <div class="field_cover select-category page_emo_tbl">
                <div>
                </div>
                <div class="sidebar_colm pointer">
                    <ul>
                        <li style="margin-bottom: 8px;" *ngFor="let item of emotions; let i = index" class="{{'link_clm_' + i}}"><a [ngStyle]="{'border':(currentIndex == item)? '2px solid #000':''}"  (click)="currentIndex = item;">{{item}}</a></li>
                    </ul>
                </div>
                <div class="rightPenal">
                    <ul id="test" class="working-days">
                        <!-- color 1 -->
                        <ng-container *ngFor="let data of emotionsData[currentIndex]">
                            <li>
                                <div class="select-btn">
                                    <input type="checkbox" id="data.id" name="" (change)="data.checked = !data.checked;prepareGenericEmotionObj()">
                                    <span [ngStyle]="{'background':data.checked? data.color_code:'','border':'1px solid '+data.color_code}" class="days-name">{{data.name}}</span>
                                </div>
                            </li>
                        </ng-container>
                        <div class="clear"></div>
                        <!-- end -->
                    </ul>
                    <div class="footerwrap" *ngIf="showNext">
                        <div class="actionbox-rt" style="padding-bottom:10px">
                            <span *ngIf="noEmotionSelected" class="warnings">Please select at least 1 emotion.</span>
                        </div><br/>
                        <div class="actionbox-rt buttonwrap">
                            <a  [class.disable]="reqEmotionDataArr.length == 0" class="clear-all commonBtn button_padding clear_btn" (click)="clearAllSelection()"> Clear All </a>
                            <a [class.disable]="reqEmotionDataArr.length == 0" class="nextBtn commonBtn button_padding" (click)="showIntensity()"> Next </a>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ng-container>
    <app-emotion [existingEmotionData]="myCommonArrEmotions" (emotionData)="handleEmotionData($event)"></app-emotion>
</ng-container> -->