<div class="wrapper login-section">
    <!-- ==== loginHeader ===== -->
    <div class="loginHeader">
        <div class="container">
            <a *ngIf="webType!=1"  routerLink="/" class="logo">
                <img src="assets/images/Growthdrivers.svg" />
            </a>
            <div *ngIf="webType==1">
                <app-loginheader></app-loginheader>
            </div>
        </div>
    </div>

    <!-- loginHeader end  -->
    <div class="main_container">
        <div class="container">
            <div class="title-bar">
                <h1><a class="backArrow pointer" (click)="goBack()">FAQs </a></h1>
            </div>
            <!-- <div class="heading">
                <h4 class="extrabold"><a class="backArrow pointer" (click)="goBack()">FAQs </a></h4>
            </div> -->
            <div class="faq" id="accordion">
                <ul>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-1">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-1" data-aria-expanded="true" data-aria-controls="faqCollapse-1">What is a Growthdriver?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-1" class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                                <div class="card-body">
                                    <p>A Growthdriver is an analysis of the beliefs, emotions, meanings, and behaviors that you
                                        have experienced in relation to a particular event that has occurred in your life. All Growthdriver
                                        entries are in response to a particular event. By documenting your reflection of how a particular
                                        event triggered specific beliefs, emotions, meanings, and behaviors, you will be able to develop a
                                        language and internal monologue to accurately diagnose, label, and comprehend how your feelings
                                        and subsequent actions ultimately determine your present circumstances. Additionally, you can log
                                        individual entries within one Growthdriver. For example, if a Growthdriver was created in response
                                        to a particular, recurring event, you can log individual entries within the Growthdriver to reflect
                                        upon each instance of the recurring event.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-2">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-2" data-aria-expanded="false" data-aria-controls="faqCollapse-2">What is an Action Item?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-2" class="collapse" aria-labelledby="faqHeading-2" data-parent="#accordion">
                                <div class="card-body">
                                    <p> An Action Item is an optional component of a Growthdriver. Action Items are short, quick,
                                        tangible tasks that can be completed as soon as possible to support a Growthdriver. For instance, if
                                        you create a Growthdriver to reflect upon a negative event that has occurred in your life, you can
                                        create constituent Action Items that help support an improvement upon the negative event.
                                        Likewise, if you create a Growthdriver to reflect upon a positive event that has occurred in your life,
                                        you can create constituent Action Items that reinforce the positive event.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-3">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-3" data-aria-expanded="false" data-aria-controls="faqCollapse-3"> What is a Goal? <i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-3" class="collapse" aria-labelledby="faqHeading-3" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Whereas a Growthdriver is an analysis of an event that has previously occurred in your life,
                                        a Goal documents a desired outcome that you wish to achieve in the future. When creating a Goal,
                                        you explore the future beliefs, emotions, meanings, and behaviors that you wish to experience in
                                        relation to a specific, future outcome. Additionally, you can link individual Growthdrivers to a given
                                        Goal. Accordingly, a Goal can act as a container with individual, constituent Growthdrivers that
                                        support the achievement of said Goal.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-4">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-4" data-aria-expanded="false" data-aria-controls="faqCollapse-4">What is a Milestone?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-4" class="collapse" aria-labelledby="faqHeading-4" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Whereas an Action Item tends to be more immediate and short-sighted, Milestones tend
                                        to be bigger tasks, requiring a longer duration of time to complete, that support the achievement of
                                        a Goal. For instance, if you create a Goal titled &#39;Feel Confident in a Bathing Suit,&#39; you may choose to
                                        create constituent, supporting Milestones such as &#39;Lose 20 lbs.&#39; or &#39;Drop Three Pant Sizes&#39;.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-5">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-5" data-aria-expanded="false" data-aria-controls="faqCollapse-5"> What is a Project?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-5" class="collapse" aria-labelledby="faqHeading-5" data-parent="#accordion">
                                <div class="card-body">
                                    <p>A Project is simply a very big-picture, long-term Goal. For instance, whereas you may
                                        create a Goal titled &#39;Feel Confident in a Bathing Suit,&#39; you could also create a Project titled &#39;Improve
                                        Self-Confidence&#39; or even &#39;Learn to Love Myself.&#39; Similarly to how you can link individual, constituent
                                        Growthdrivers to a given Goal, you can also link individual, constituent Goals to a given Project.
                                        Accordingly, the achievement of multiple, relevant Goals can also enable you to achieve a big-picture, long-term Project.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-6">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-6" data-aria-expanded="false" data-aria-controls="faqCollapse-6">How do I create an entry?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-6" class="collapse" aria-labelledby="faqHeading-6" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        To create an entry, simply click on the &#39;CREATE NEW ENTRY&#39; button, located toward the
                                        top-right of the web app screen, and then select the desired entry type. You could also navigate to
                                        the submodule of the desired entry type and click on the plus sign button. Within the mobile apps,
                                        you can create an entry by tapping on the hovering ‘create’ button located toward the bottom-right
                                        of the screen. Once you have entered information into all of the entry&#39;s mandatory fields, you will
                                        then be able to create your entry. If you would like to return to the particular entry for completion
                                        at a later time, simply click on the &#39;PENDING&#39; button. You will be able to access your created entries
                                        within your Created library, as well as your saved entries within your Pending library, accessible
                                        within the specific entry submodule.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-7">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-7" data-aria-expanded="false" data-aria-controls="faqCollapse-7">How do I conquer an entry?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-7" class="collapse" aria-labelledby="faqHeading-7" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        After you have created an entry, you have a documented lesson, or motivation, providing
                                        insight about your typical behavior in response to particular events. As you revisit your completed
                                        entries, you will begin to consider the ways in which you can “overcome” your obstacles or
                                        “achieve” your desired outcomes. When you are confident that you have accomplished such feats,
                                        you may believe that you have conquered a particular entry. Once you reach this conclusion, you
                                        can then mark the particular entry as &#39;conquered,&#39; signifying personal development and
                                        empowerment! You&#39;re unstoppable! When you mark an entry as &#39;conquered,&#39; you will then
                                        determine if you want to save the conquered entry to your Conquered library, which is a limited-space library comprised of previously conquered entries.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-8">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-8" data-aria-expanded="false" data-aria-controls="faqCollapse-8">What is the Conquered library?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-8" class="collapse" aria-labelledby="faqHeading-8" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Within the Growthdrivers application, the Conquered library is essentially a repository
                                        where you can save previously conquered entries for future review. After you conquer an entry, you
                                        will be required to determine if you would like to save the particular entry to your Conquered
                                        library. If you save the entry to your Conquered library, it will be kept safe and secure. If you choose
                                        not to save the particular entry to your Conquered library, then the entry will be purged from the
                                        system.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-9">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-9" data-aria-expanded="false" data-aria-controls="faqCollapse-9">How do I know the emotional intensity that I experience?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-9" class="collapse" aria-labelledby="faqHeading-9" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        The intensity measurement is personal and subjective. For instance, your relative level of
                                        intensity scale may be slightly different from another person&#39;s relative level of intensity scale.
                                        However, at the end of the day, you will be the only person reviewing your contents within the
                                        Growthdrivers application — nobody else. Through these metrics, you will practice the act of
                                        “exploring” your emotions. Emotions are complex and personal. Through the act of exploring the
                                        various dimensions of your emotions, you will become more skilled at identifying and managing
                                        your emotions, effectively strengthening your emotional awareness.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-10">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-10" data-aria-expanded="false" data-aria-controls="faqCollapse-10">What is a Growthmantra?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-10" class="collapse" aria-labelledby="faqHeading-10" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        A Growthmantra is an auditory tool you can use to gain a different perspective on your
                                        previously-created Growthdriver and Goal entries. After you create an entry, you will then be able
                                        to convert desired aspects of said entry into an audio file that reads selected contents of the entry
                                        out loud, per your specified criteria. When you read your own thoughts, feelings, and consequential
                                        actions, you are analyzing such details through your own internal voice. As humans, we all tend to
                                        be a bit biased. Accordingly, our interpretations of details surrounding a particular event tend to be
                                        biased as well. In many cases, such bias is actually counterproductive to our personal development
                                        and wellbeing. To help you overcome such bias, Growthmantras enable you to analyze your own
                                        thoughts, feelings, and consequential actions in regard to a particular event through the context of
                                        another voice.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-11">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-11" data-aria-expanded="false" data-aria-controls="faqCollapse-11">How do I create a Growthmantra?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-11" class="collapse" aria-labelledby="faqHeading-11" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Growthmantras can be created through the Growthdrivers web app and mobile apps.
                                        Please keep in mind that, in order to create a Growthmantra, you will first have to create either a
                                        Growthdriver or Goal entry. Within the mobile apps, you can create a Growthmantra by, first,
                                        navigating to the Mantras module. Next, you will tap on the ‘create’ hovering button located toward
                                        the bottom-right corner of the screen, which will prompt you to select the desired entry from which
                                        you wish to create a Growthmantra. Next, you will select the specific content, from the previously-selected entry,
                                        that you wish to include in the Growthmantra. Then, you will select the particular
                                        voice that you want to read your Growthmantra out loud. Finally, you will enter a title for the
                                        Growthmantra. Congratulations! You&#39;ve now created your first Growthmantra! You will be able to
                                        manage your Growthmantras through both the Growthdrivers web app and mobile apps.    
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-12">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-12" data-aria-expanded="false" data-aria-controls="faqCollapse-12">What is a Growthmantra Playlist?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-12" class="collapse" aria-labelledby="faqHeading-12" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        You can view each Growthmantra as a unique audio file, similar to a song. Just as you can
                                        create playlists of individual songs, Growthdrivers allows you to create playlists of individual
                                        Growthmantras. Growthmantra Playlists can be utilized to group related Growthmantras together in
                                        one safe, convenient location.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-13">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-13" data-aria-expanded="false" data-aria-controls="faqCollapse-13">What is an Observation?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-13" class="collapse" aria-labelledby="faqHeading-13" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        An Observation is an entry type that allows Growthdrivers users to create collaborative
                                        feedback forms for other Growthdrivers users within their respective organization. A form of 360
                                        feedback, Growthdrivers users can create Observations to gather feedback from respondents both
                                        inside and outside of the Observation creator’s organization. For instance, Bob can create an
                                        Observation for Annie and can send invitations to people who Annie has previously worked with,
                                        both internal and external to their organization, to provide feedback on the experience of working
                                        with Annie. Observations can also include collaborators from the Observation creator’s respective
                                        organization. Gathering 360 feedback through Observations leads to greater collaboration and more
                                        candid and constructive feedback. Because all feedback is submitted anonymously, respondents can
                                        remain confident and free to provide their honest feedback for the Observation’s subject.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-14">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-14" data-aria-expanded="false" data-aria-controls="faqCollapse-14">How do I create an Observation?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-14" class="collapse" aria-labelledby="faqHeading-14" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        To create an Observation, simply navigate to the Observations submodule within the
                                        Review module of the Growthdrivers web app. Then, click on the ‘create’ plus sign button located
                                        toward the top-right of the screen and enter all required information into the Observation. Once all
                                        required information is provided for the Observation, you will then click on the CREATE AND SEND
                                        button which successfully creates the Observation and sends the invitations to provide feedback for
                                        the Observation to the desired respondents. 
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-15">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-15" data-aria-expanded="false" data-aria-controls="faqCollapse-15">What is the Leaderboard?
                                        <i class="mdi mdi-chevron-down rotate-icon float-right"></i>
                                    </h5>
                                </div>
                            </div>
                            <div id="faqCollapse-15" class="collapse" aria-labelledby="faqHeading-15" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        The leaderboard displays information related to competition amongst users within your
                                        organization’s Growthdrivers account. You will be able to compete with other users to earn points
                                        for various interactions with the system. Through competition, you can be a member of various
                                        teams within your respective organization, and collectively earn points by accomplishing various
                                        tasks within the Growthdrivers application. However, please keep in mind that you will only be able
                                        to compete with other users within your respective organization. Please refer to the Leaderboard
                                        submodule to view rankings, the progress of your respective team(s), your individual progress within
                                        the system, as well as other competition-related stats.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-16">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-16" data-aria-expanded="false" data-aria-controls="faqCollapse-16">How do I earn points?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-16" class="collapse" aria-labelledby="faqHeading-16" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        You can earn points by completing various interactions, and accomplishing specific tasks,
                                        within the Growthdrivers system. As a member of a team, your team will collectively earn points as
                                        a result of your teammates&#39; interactions with the system. Points can be earned for creating entries,
                                        conquering entries, composing Growthmantras, listening to Growthmantras, etc. In addition to
                                        points, your interactions with the system will also be rewarded through badges.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-17">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-17" data-aria-expanded="false" data-aria-controls="faqCollapse-17">How do I earn badges?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-17" class="collapse" aria-labelledby="faqHeading-17" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Similarly to points, you can earn badges for completing various interactions, and
                                        accomplishing specific objectives, within the Growthdrivers system. There is a correlation between
                                        points and badges: the more points you earn, to some degree, the more badges you accumulate.
                                        The mission is to earn every badge possible within the system. To view your badges, simply navigate
                                        to the Badges submodule.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-18">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-18" data-aria-expanded="false" data-aria-controls="faqCollapse-18">Why do I have to use a Username and an Avatar?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-18" class="collapse" aria-labelledby="faqHeading-18" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        We take user data privacy and security very seriously. Analyzing and documenting your
                                        personal emotions, beliefs, meanings, behaviors, etc. in relation to a particular event that has
                                        occurred in your life is not only challenging, but also confidential. To provide users the confidence to
                                        analyze and document such personal information through the Growthdrivers application, we&#39;ve
                                        worked tirelessly to implement rigorous privacy and security measures. Furthermore, we believe
                                        that user anonymity within the Growthdrivers application is imperative to maintaining user trust
                                        and confidence. By using a username and an avatar, you can remain confident that you will be the
                                        only individual with access to your personal information within the Growthdrivers application.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-19">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-19" data-aria-expanded="false" data-aria-controls="faqCollapse-19">How do I create and manage teams within Growthdrivers?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-19" class="collapse" aria-labelledby="faqHeading-19" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Growthdrivers turns engagement into a team sport. When the teams in your organization
                                        are engaged, your organization, at large, becomes engaged. Organizations who are more engaged in
                                        business goals and tasks tend to have better implementation records than organizations with lower
                                        levels of engagement. Within the admin console, which is only accessible within the Growthdrivers
                                        web app to Admins and Super Admins, decisionmakers within your organization have access to a
                                        wide range of administrative functionality, including the ability to create and manage teams within
                                        Growthdrivers. To create a team, simply navigate to the Team Management submodule within the
                                        admin console and click on the ‘create’ plus sign button located toward the top-right of the screen.
                                        Upon entering all required information and adding all desired Growthdrivers users from within your
                                        organization to the given team, you then click on the CREATE TEAM button located toward the
                                        bottom-right side of the screen. To manage your organization’s teams within Growthdrivers, simply
                                        select the desired team within the Team Management submodule, then click on the three-dot menu
                                        located toward the top-right of the team and, finally, click on the Edit button.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-20">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-20" data-aria-expanded="false" data-aria-controls="faqCollapse-20">How do I onboard members into my organization’s Growthdrivers account?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-20" class="collapse" aria-labelledby="faqHeading-20" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Onboarding your people into your organization’s Growthdrivers account is a quick, painless
                                        process. Upon creating your first Growthdrivers user profile, you can then invite other members
                                        from your organization to onboard into the Growthdrivers application by navigating to the Member
                                        Management submodule within the admin console and clicking on the ‘create’ plus sign button
                                        located toward the top-right of the screen. After clicking on this button, you will then add all of the
                                        email addresses of the members you want to onboard into Growthdrivers and then click on the
                                        INVITE button, at which point the system will send invitations to the new users’ email addresses. If
                                        you intend to onboard a large number of users into the system, you can also utilize the Invitation
                                        Queue feature, which allows you to add many email addresses to a queue which you can return to
                                        in the future to then send invitations to the intended recipients. To ensure successful deliverability,
                                        you can also copy a unique invitation hyperlink for each user to onboard into the system.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-21">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-21" data-aria-expanded="false" data-aria-controls="faqCollapse-21">What user roles exist within the Growthdrivers application?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-21" class="collapse" aria-labelledby="faqHeading-21" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        To keep the Growthdrivers application as simple as possible, there exist three total user
                                        roles in the system: 1) Member, 2) Admin, and 3) Super Admin. Super Admins and Admins tend to
                                        be leaders and decisionmakers within a given organization, while Members typically include non-decisionmakers,
                                        such as analysts, junior level staff, and other employees. Please note that only
                                        Super Admins and Admins are able to access the admin console within the Growthdrivers web app.
                                        That said, updating a member’s user role is a simple, quick process that can be completed through
                                        the admin center.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-22">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-22" data-aria-expanded="false" data-aria-controls="faqCollapse-22">How can I remain aware of my organization’s interactions inside Growthdrivers?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-22" class="collapse" aria-labelledby="faqHeading-22" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Growthdrivers provides many ways for you to remain aware of how the users within your
                                        organization interact with the application. For instance, you can check how many points a specific
                                        user has earned in the system, the last date and time that a specific user has logged into the system,
                                        etc. To check this information, you can visit the Leaderboard, generate reports, and utilize
                                        administrative functionality within the admin console.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-23">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-23" data-aria-expanded="false" data-aria-controls="faqCollapse-23">Am I able to generate reports within the Growthdrivers application?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-23" class="collapse" aria-labelledby="faqHeading-23" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Absolutely! Growthdrivers provides many metrics, analytics, KPIs, and reports so that you can remain aware of your organization’s real-time levels of engagement and risks for burnout.
                                        Depending on your user role, you can generate reports on an individual user level, a team level, and even an organization level.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-24">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-24" data-aria-expanded="false" data-aria-controls="faqCollapse-24">How can Growthdrivers help me remain cognizant of my organization’s levels of engagement,
                                        risks for burnout, etc.?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-24" class="collapse" aria-labelledby="faqHeading-24" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        Through various metrics, analytics, KPIs, and custom reports, Growthdrivers provides you the essential tools to remain aware of employee engagement and burnout, amongst many other critical factors.
                                        In particular, Guidewise members love the Insights tab within the admin console that provide essential, accurate, and real-time analytics and KPIs that help you keep your finger on your organization’s pulse. For instance,
                                        the Insights tab includes such analytics and KPIs as Emotions Experienced, Intensity Experienced, Conquered Entries, Goal Deadline Compliance, Project Deadline Compliance, plus many others.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="card">
                            <div class="card-header" id="faqHeading-25">
                                <div class="mb-0">
                                    <h5 class="faq-title" data-toggle="collapse" data-target="#faqCollapse-25" data-aria-expanded="false" data-aria-controls="faqCollapse-25">If I need additional assistance, what should I do?<i class="mdi mdi-chevron-down rotate-icon float-right"></i></h5>
                                </div>
                            </div>
                            <div id="faqCollapse-25" class="collapse" aria-labelledby="faqHeading-25" data-parent="#accordion">
                                <div class="card-body">
                                    <p>
                                        If you need additional assistance, we encourage you to visit our Support submodule. Here,
                                        you will be able to access instructional videos and submit support tickets to our support team.
                                        Additionally, you can also email our support team directly by reaching out to <a href="mailto:support@guidewise.io">support@guidewise.io.</a>
                                        Submissions to our support team are private and secure. Plus, our support team is very friendly!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <div class="clear"></div>
                </ul>
            </div>
            <div class="reach_tous">
                <p>For additional information, please contact us at <a href="mailto:info@guidewise.io">info@guidewise.io</a>.</p>
            </div>
        </div>

        <div class="footerWrap">
            <div class="copyright">
                <a class="_logo_a mt-2" href="https://www.guidewise.io/" target="_blank" rel="noopener noreferrer">
                    <img class="_logo" src="../../../assets/images/guidewise_primary mark_final.jpg">
                </a>
                <span>Growthdrivers R2.0.0</span>
                <p>&copy; 2021 - 2023 Guidewise</p>
            </div>
        </div>

    </div>