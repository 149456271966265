import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { apiEndPoint } from '../../shared/apiEndPoints';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import * as CONSTANT from './../../services/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  pwdResetForm: FormGroup = null;
  warningMsg = '';
  isForgotMailSend = false;

  subscription: Subscription;

  constructor(private api: AdminService, private readonly router: Router) { }

  ngOnInit(): void {
    this.pwdResetForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(CONSTANT.EMAIL_REGEX), Validators.required])
    })
  }
  sendEmail() {
    if (this.pwdResetForm.controls.email.status === 'INVALID') {
      this.warningMsg = 'Please enter a valid email address';
      setTimeout(() => {
        this.warningMsg = '';
      }, 2000);
    } else {
      let data = {
        email: this.pwdResetForm.value.email,
        user_type: '2',
      }
      this.subscription = this.api.postData(apiEndPoint.FORGOT_PASSWORD, data).subscribe(async (response) => {
        if (response.status == '404') {
          this.warningMsg = '';
          this.api.errorToast('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">'+ response.message+'</span>','');

        } else {
          this.api.toast1('<i class="mdi bigSize  mdi-check-circle-outline"></i><span class="font_size">Successfully sent</span>', '');
          this.warningMsg = '';
          this.isForgotMailSend = true;
        }

      },
        (error: HttpErrorResponse) => {
          this.warningMsg = 'The email address entered is not registered with us.';
        });
    }
  }

  ngOnDestroy(): void {
  }


}
